/*! _animations.scss | Thinq for Yourself */

/* ==========================================================================
Animations
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Fade in Up animation
1. Levitate animation
2. Infinite rotate
3. Gelatine
4. Pulse animation
=============================================================================
***/

/* ==========================================================================
0. Fade in Up animation
========================================================================== */

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}


.preFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


/* ==========================================================================
1. Levitate animation
========================================================================== */

@-webkit-keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px); }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); } }

@keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px); }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); } }


.levitate {
    -webkit-animation-name: levitate;
    animation-name: levitate;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}


/* ==========================================================================
2. Infinite rotate
========================================================================== */

@keyframes rotating {
    from {
        transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 6s linear infinite;
    -moz-animation: rotating 6s linear infinite;
    -ms-animation: rotating 6s linear infinite;
    -o-animation: rotating 6s linear infinite;
    animation: rotating 6s linear infinite;
}


/* ==========================================================================
3. Gelatine animation
========================================================================== */

@-webkit-keyframes gelatine {
    from, to { -webkit-transform: scale(1, 1); }
    25% { -webkit-transform: scale(0.9, 1.1); }
    50% { -webkit-transform: scale(1.1, 0.9); }
    75% { -webkit-transform: scale(0.95, 1.05); }
}
@keyframes gelatine {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.9, 1.1); }
    50% { transform: scale(1.1, 0.9); }
    75% { transform: scale(0.95, 1.05); }
}
//Settings
.gelatine {
    -webkit-animation: gelatine 0.6s;
    animation: gelatine 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ==========================================================================
4. Pulse animation
========================================================================== */

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    25% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(4.5);
        opacity: 0;
    }
}