/*! core.scss | Thinq for Yourself */

/***
TABLE OF CONTENTS
=============================================================================
0. CSS Imports
=============================================================================
***/

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import 'partials/theme-default';
@import 'partials/colors';
@import 'partials/helpers';
@import 'partials/cards';
@import 'partials/dropdowns';
@import 'partials/labels';
@import 'partials/layout';
@import 'partials/layout-utils';
@import 'partials/layout-animations.scss';
@import 'partials/sections';
@import 'partials/features';
@import 'partials/buttons';
@import 'partials/timeline';
@import 'partials/team';
@import 'partials/faq';
@import 'partials/blog';
@import 'partials/footer';
@import 'partials/forms';
@import 'partials/forms_ext';
@import 'partials/checkbox_ext';
@import 'partials/pageloader';
@import 'partials/animations';
@import 'partials/messages';
@import 'partials/responsive';
@import 'partials/testimonials';
@import 'partials/nav';
@import 'partials/dialogs';
