/*! _colors.scss | Thinq for Yourself */

/* ==========================================================================
Thinq for Yourself global colors 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Color variables
1. State colors
2. Social colors
3. Predefined gradients
4. Predefined box shadows
=============================================================================
***/

/* ==========================================================================
0. Variables
========================================================================== */


$primary: #453863;
$secondary: #63384a;
$accent: #0e9966;

$white: #fff;
$smoke-white: #fcfcfc;
$accent-grey: #ccc;
$grey-white: #f2f2f2;
$grey-white-accent: #e2e2e2;
$command-grey: #fafbfc;
$light-grey: #f9f9f9;
$ultra-light-grey: #f7fcff;
$title-grey: #A9ABAC;
$blue-grey: #444F60;
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$muted-grey: #999;
$section-grey: #fcfcfc;
$section-grey-accent: #efefef;
$background-grey: #f3f5f7;
$header-grey: #F5F9FC;
$default-input: #ccc;
$placeholder: #cecece;
$fade-grey: #ededed;
$sidebar: #344258;
$black: #000;
$coal: #444444;
$grey: #ccc;
$grey-6: #666;
$grey-5: #555;
$grey-3: #333;
$cream-grey: #f6f4ff;
$icon-grey: #818a97;
$border-grey: #d4dadf;
$dashboard-grey: #f4f6fb;
$link-grey: #f5f5f5;
$chosen-grey: #e0e0e0;
$chosen-dark-grey: #66676b;
$dark-text: #4a4a4a;
$light-blue: rgba(94,147,255,.9);


/* ==========================================================================
1. State colors
========================================================================== */

$sidebar: #344258;
$red: #FF7273;
$blue: #039BE5;
$green: #00b289;
$orange: #eda514;


/* ==========================================================================
2. Social colors
========================================================================== */

$facebook: #3B5998;
$twitter: #1dcaff;
$linkedin: #007bb6;
$tumblr: #35465c;
$github: #444;
$dribbble: #ea4c89;
$google-plus: #d34836;
$youtube: #cc181e;
$reddit: #ff4500;


/* ==========================================================================
3. Predefined gradients
========================================================================== */

// Modal gradient
$modal-ga: $secondary-accent;
$modal-gb: $secondary;

$webkit-modal-gradient: -webkit-linear-gradient(45deg, $modal-ga 0%, $modal-gb 100%);
$modal-gradient: linear-gradient(45deg, $modal-ga 0%, $modal-gb 100%);

// Coal
$coal-a: $blue-grey;
$coal-b: #444;

$webkit-coal-gradient: -webkit-linear-gradient(45deg, $coal-a, $coal-b); /* Chrome 10-25, Safari 5.1-6 */
$coal-gradient: linear-gradient(45deg, $coal-a, $coal-b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// Hero 
$webkit-hero-gradient: -webkit-linear-gradient(to right, $accent, $secondary); 
$hero-gradient: linear-gradient(to right, $accent, $secondary); 



/* ==========================================================================
4. Predefined box shadows
========================================================================== */

$base-shadow: rgba(0, 0, 0, 0.12);

//Orange box shadow
$orange-shadow-from: rgba(237, 165, 20, 0.42);
$orange-shadow-to: rgba(237, 165, 20, 0.2);
$orange-box-shadow:  0 14px 26px -12px $orange-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $orange-shadow-to !important;

//Green box shadow
$green-shadow-from: rgba(0, 178, 137, 0.42);
$green-shadow-to: rgba(0, 178, 137, 0.2);
$green-box-shadow:  0 14px 26px -12px $green-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $green-shadow-to !important;

//Red box shadow
$red-shadow-from: rgba(255, 114, 115, 0.42);
$red-shadow-to: rgba(255, 114, 115, 0.2);
$red-box-shadow:  0 14px 26px -12px $red-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $red-shadow-to !important;

//Blue box shadow
$blue-shadow-from: rgba(3, 155, 229, 0.42);
$blue-shadow-to: rgba(3, 155, 229, 0.2);
$blue-box-shadow:  0 14px 26px -12px $blue-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $blue-shadow-to !important;

$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(0, 209, 178, 0.42);
$primary-shadow-to: rgba(0, 209, 178, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(127, 0, 255, 0.42);
$secondary-shadow-to: rgba(127, 0, 255, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(83,109,254, 0.42);
$accent-shadow-to: rgba(83,109,254, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;
